import React from 'react';

const Envelope = () => (
  <svg aria-labelledby="emailTitle emailDesc" role="img" version="1.1" viewBox="0 0 60 60" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
    <title id="emailTitle">Email</title>
    <desc id="emailDesc">Email icon</desc>
    <path d="M0,8.5v2.291v38.418V51.5h60v-2.291V10.791V8.5H0z M36.625,30.564l-5.446,5.472c-0.662,0.615-1.698,0.614-2.332,0.026
	l-5.473-5.498l0.048-0.047L3.647,10.5h52.719L36.577,30.518L36.625,30.564z M20.524,30.533L2,48.355V11.923L20.524,30.533z
	 M21.934,31.95l5.523,5.549c0.709,0.661,1.619,0.993,2.533,0.993c0.923,0,1.85-0.339,2.581-1.02l5.496-5.522L56.304,49.5H3.686
	L21.934,31.95z M39.477,30.534L58,11.922v36.433L39.477,30.534z"
    />
  </svg>
);

export default Envelope;
