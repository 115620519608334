import * as React from 'react';
import * as styles from './Banner.module.scss';

import { Link } from 'gatsby';

import Decoration from '../Decoration';
import Heading from '../Heading';
import Logo from '../Logo';

export const Banner = () => (
  <div className={styles.root}>
    <Decoration>
      <Link to="/">
        <Logo />
      </Link>
    </Decoration>
    <Heading align="center" level={1} lookslike={3}>
      Custom Desserts For Any Occasion
    </Heading>
  </div>
);
