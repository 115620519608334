import cx from 'classnames';
import * as React from 'react';

import { Facebook, Instagram, Yelp } from '../../icons';

import * as styles from './Footer.module.scss';

import Legal from '../../components/Legal';

export const Footer = () => (
  <footer className={styles.root}>
    <div className={styles.footerContainer}>
      <div className={styles.socialIcons}>
        <a
          href="https://www.facebook.com/StellaSweetsDesserts/"
          rel="noopener noreferrer"
          target="_blank"
          title="facebook (opens in a new window)"
        >
          <Facebook color1={styles.color1} color2={styles.color2} />
        </a>
        <a
          href="https://www.instagram.com/stellasweetsdesserts/"
          rel="noopener noreferrer"
          target="_blank"
          title="instagram (opens in a new window)"
        >
          <Instagram color1={styles.color1} color2={styles.color2} />
        </a>
        <a
          href="https://www.yelp.com/biz/stellas-sweets-mission-viejo-2"
          rel="noopener noreferrer"
          target="_blank"
          title="yelp (opens in a new window)"
        >
          <Yelp color1={styles.color1} color2={styles.color2} />
        </a>
      </div>

      <div>&copy; {new Date().getFullYear()} StellaSweetsDesserts.com</div>

      <Legal>
        Icons made by{' '}
        <a
          href="https://www.flaticon.com/authors/freepik"
          rel="noopener noreferrer"
          target="_blank"
          title="Freepik"
        >
          Freepik
        </a>{' '}
        &amp;{' '}
        <a
          href="https://www.flaticon.com/authors/smashicons"
          rel="noopener noreferrer"
          target="_blank"
          title="Smashicons (opens in a new window)"
        >
          Smashicons
        </a>{' '}
        from{' '}
        <a
          href="https://www.flaticon.com/"
          rel="noopener noreferrer"
          target="_blank"
          title="Flaticon (opens in a new window)"
        >
          www.flaticon.com
        </a>
      </Legal>
    </div>
  </footer>
);
