import React from 'react';

const Facebook = props => (
  <svg
    aria-labelledby="fbTitle fbDesc"
    role="img"
    version="1.1"
    viewBox="0 0 512 512"
    x="0px"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <title id="fbTitle">Facebook</title>
    <desc id="fbDesc">Facebook icon</desc>
    <path
      className={props.color1}
      d="M476.69,512H35.31C15.89,512,0,496.11,0,476.69V35.31C0,15.89,15.89,0,35.31,0H476.69
	C496.11,0,512,15.89,512,35.31V476.69C512,496.11,496.11,512,476.69,512"
    />
    <path
      className={props.color2}
      d="M432.552,88.276v88.276h-79.448c-9.71,0-17.655,7.945-17.655,17.655v52.966h88.276v88.276h-88.276
	V512h-88.276V335.448h-70.621v-88.276h70.621v-52.966c0-55.614,42.372-101.517,97.103-105.931H432.552z"
    />
  </svg>
);

export default Facebook;
