import React from 'react';

const Menu = () => (
  <svg aria-labelledby="menuTitle menuDesc" role="img" version="1.1" viewBox="0 0 50 50"
    x="0px" xmlns="http://www.w3.org/2000/svg" y="0px"
  >
    <title id="menuTitle">Menu</title>
    <desc id="menuDesc">Menu icon</desc>
    <g>
      <rect height="4" width="50" y="2" />
      <rect height="4" width="50" y="16" />
      <rect height="4" width="50" y="30" />
      <rect height="4" width="50" y="44" />
    </g>
  </svg>
);

export default Menu;
