import * as React from 'react';
import * as styles from './Decoration.module.scss';

export const Decoration = ({ children }) => (
  <div className={styles.root}>
    <div className={styles.decorationBar} />
    <div className={styles.decorationChildren}>{children}</div>
    <div className={styles.decorationBar} />
  </div>
);
