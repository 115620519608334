import React from 'react';

const Palette = () => (
  <svg viewBox="0 0 512.001 512.001" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px" >
    <g>
      <g>
        <path d="M400.123,459.297c-2.759-3.069-7.483-3.321-10.554-0.561c-18.149,16.317-40.875,27.606-67.545,33.556
			c-25.318,5.647-52.957,6.275-82.154,1.866c-58.448-8.828-114.594-36.516-154.043-75.964c-38.51-38.51-61.988-87.42-67.896-141.442
			c-5.738-52.464,6.332-105.905,33.987-150.479c2.176-3.507,1.097-8.114-2.41-10.289c-3.508-2.176-8.113-1.097-10.289,2.41
			C9.812,165.793-3.024,222.608,3.075,278.376c3.047,27.857,10.635,54.773,22.554,80.001c12.334,26.105,29.032,49.786,49.631,70.385
			c41.658,41.657,100.842,70.88,162.378,80.174c13.529,2.044,26.764,3.065,39.639,3.065c16.653,0,32.703-1.708,48-5.121
			c29.207-6.516,54.2-18.974,74.284-37.03C402.631,467.091,402.882,462.366,400.123,459.297z"
        />
      </g>
    </g>
    <g>
      <g>
        <path d="M255.214,66.708c-17.138-17.138-45.023-17.138-62.162,0c-17.138,17.138-17.138,45.023,0,62.162
			c8.569,8.568,19.826,12.854,31.082,12.854s22.512-4.284,31.081-12.854C272.352,111.733,272.352,83.847,255.214,66.708z
			 M244.647,118.302c-11.311,11.311-29.716,11.311-41.027,0c-11.31-11.31-11.31-29.716,0-41.027
			c5.655-5.655,13.085-8.484,20.513-8.484s14.858,2.828,20.513,8.484C255.957,88.587,255.957,106.991,244.647,118.302z"
        />
      </g>
    </g>
    <g>
      <g>
        <path d="M145.067,144.269c-17.138-17.138-45.024-17.138-62.162,0c-17.138,17.138-17.138,45.023,0,62.162
			c8.57,8.57,19.823,12.854,31.081,12.854c11.255,0,22.513-4.285,31.082-12.854C162.205,189.293,162.205,161.408,145.067,144.269z
			 M134.499,195.864c-11.311,11.309-29.717,11.31-41.027,0c-11.311-11.31-11.311-29.716,0-41.027
			c5.655-5.655,13.085-8.483,20.513-8.483c7.43,0,14.858,2.828,20.513,8.483C145.811,166.148,145.811,184.553,134.499,195.864z"
        />
      </g>
    </g>
    <g>
      <g>
        <path d="M130.151,273.367c-17.138-17.138-45.023-17.138-62.162,0c-17.138,17.139-17.138,45.024,0,62.162
			c8.569,8.57,19.825,12.854,31.081,12.854c11.257,0,22.512-4.284,31.082-12.854C147.289,318.391,147.289,290.505,130.151,273.367z
			 M119.583,324.962c-11.31,11.311-29.715,11.311-41.027,0c-11.31-11.31-11.31-29.716,0-41.027
			c5.655-5.655,13.085-8.484,20.513-8.484s14.858,2.828,20.513,8.484C130.894,295.246,130.894,313.65,119.583,324.962z"
        />
      </g>
    </g>
    <g>
      <g>
        <path d="M353.383,372.34c-17.138-17.139-45.024-17.137-62.163,0c-17.138,17.138-17.138,45.023,0,62.162
			c8.569,8.569,19.826,12.854,31.082,12.854s22.512-4.284,31.082-12.854C370.521,417.364,370.521,389.478,353.383,372.34z
			 M342.814,423.935c-11.309,11.312-29.716,11.311-41.026,0c-11.312-11.311-11.312-29.716,0-41.027
			c5.655-5.655,13.084-8.484,20.513-8.484c7.429,0,14.857,2.828,20.513,8.484C354.127,394.22,354.127,412.624,342.814,423.935z"
        />
      </g>
    </g>
    <g>
      <g>
        <path d="M501.699,55.218c-11.115-11.116-29.043-11.661-40.813-1.239l-68.912,61.011c0.377-17.716-3.494-34.257-11.612-49.356
			c-10.135-18.852-26.891-34.827-48.457-46.197C297.7,1.4,252.383-4.44,207.578,3.415c-49.778,8.726-95.533,32.782-132.318,69.568
			c-6.066,6.066-11.873,12.492-17.263,19.1c-2.608,3.198-2.13,7.906,1.068,10.513c3.196,2.608,7.905,2.13,10.513-1.068
			c5.072-6.219,10.539-12.268,16.25-17.978c43.715-43.714,91.74-59.701,124.331-65.415c41.609-7.293,83.444-2.003,114.777,14.52
			c18.926,9.979,33.542,23.829,42.263,40.055c9.042,16.817,11.881,35.893,8.452,56.731l-61.579,54.519
			c-3.091,2.736-3.378,7.458-0.642,10.548c0.178,0.201,0.366,0.39,0.56,0.567c0.063,0.058,0.131,0.108,0.195,0.162
			c0.135,0.115,0.268,0.229,0.409,0.332c0.081,0.06,0.165,0.112,0.248,0.168c0.13,0.089,0.26,0.176,0.395,0.256
			c0.092,0.054,0.185,0.101,0.278,0.15c0.132,0.07,0.262,0.139,0.397,0.2c0.1,0.046,0.2,0.086,0.302,0.127
			c0.133,0.054,0.264,0.106,0.398,0.151c0.106,0.036,0.212,0.068,0.319,0.099c0.133,0.039,0.267,0.075,0.402,0.107
			c0.11,0.026,0.219,0.048,0.33,0.068c0.134,0.025,0.27,0.046,0.406,0.064c0.113,0.014,0.224,0.027,0.337,0.037
			c0.137,0.011,0.272,0.017,0.409,0.021c0.071,0.002,0.141,0.011,0.213,0.011c0.048,0,0.096-0.005,0.143-0.006
			c0.096-0.002,0.191-0.007,0.288-0.013c0.135-0.008,0.27-0.018,0.404-0.034c0.097-0.011,0.191-0.024,0.288-0.038
			c0.135-0.02,0.267-0.045,0.401-0.073c0.095-0.02,0.189-0.04,0.283-0.064c0.134-0.034,0.266-0.072,0.398-0.113
			c0.092-0.028,0.182-0.056,0.273-0.088c0.133-0.047,0.265-0.101,0.397-0.155c0.086-0.036,0.172-0.07,0.257-0.11
			c0.135-0.063,0.267-0.133,0.399-0.204c0.079-0.042,0.158-0.081,0.235-0.127c0.143-0.084,0.28-0.175,0.417-0.269
			c0.063-0.043,0.128-0.081,0.189-0.126c0.197-0.143,0.391-0.296,0.577-0.46L470.793,65.167c5.865-5.192,14.801-4.922,20.338,0.618
			c5.54,5.54,5.812,14.474,0.619,20.339L309.521,291.95l-44.554-44.554l36.172-32.025c3.091-2.736,3.378-7.458,0.642-10.548
			c-2.735-3.091-7.458-3.378-10.548-0.642l-42.117,37.288c-0.157,0.139-0.304,0.289-0.448,0.439
			c-0.031,0.032-0.065,0.061-0.095,0.093c-0.179,0.194-0.347,0.398-0.503,0.609c-0.003,0.004-0.007,0.008-0.011,0.013
			l-29.279,39.629c-2.922-2.867-7.614-2.852-10.514,0.048l-22.773,22.773c-1.402,1.401-2.189,3.302-2.189,5.284
			c0,1.963,0.774,3.845,2.151,5.243c-8.633,1.891-16.684,6.195-23.128,12.639c-16.475,16.475-31.425,82.997-33.076,90.528
			c-0.545,2.488,0.214,5.082,2.016,6.884c1.417,1.417,3.324,2.189,5.284,2.189c0.532,0,1.068-0.057,1.599-0.173
			c7.531-1.651,74.052-16.601,90.528-33.076c6.445-6.444,10.749-14.495,12.64-23.128c1.397,1.377,3.279,2.151,5.243,2.151
			c1.982,0,3.883-0.787,5.284-2.189l22.773-22.773c2.901-2.901,2.915-7.592,0.048-10.514l39.628-29.279
			c0.005-0.004,0.01-0.009,0.016-0.013c0.209-0.155,0.41-0.321,0.603-0.498c0.052-0.048,0.098-0.102,0.147-0.15
			c0.133-0.129,0.265-0.256,0.388-0.396l53.296-60.196c7.446,15.821,17.82,30.215,27.917,44.223
			c13.551,18.8,26.349,36.557,32.246,57c6.552,22.71,3.436,45.35-9.804,71.247c-3.147,6.156-6.728,12.052-10.643,17.523
			c-2.401,3.357-1.627,8.023,1.729,10.425c1.317,0.943,2.838,1.396,4.342,1.396c2.329,0,4.625-1.086,6.083-3.125
			c4.343-6.072,8.312-12.604,11.795-19.417c14.871-29.085,18.422-55.97,10.857-82.192c-6.617-22.936-20.781-42.589-34.481-61.596
			c-11.48-15.927-22.361-31.041-29.124-47.816L502.94,96.03C513.359,84.262,512.815,66.335,501.699,55.218z M218.109,384.024
			c-8.745,8.745-44.686,19.74-71.557,26.34c6.597-26.867,17.59-62.805,26.341-71.556c6.06-6.06,14.198-9.365,22.602-9.365
			c1.347,0,2.701,0.085,4.055,0.257l27.667,27.667C228.463,367.164,225.14,376.993,218.109,384.024z M246.559,355.575l-7.072-7.072
			l-31.073-31.073c-0.001-0.001-0.002-0.002-0.003-0.003l-7.069-7.069l12.206-12.206l15.34,15.34l29.074,29.075
			c0.003,0.003,0.007,0.006,0.01,0.009l0.792,0.792L246.559,355.575z M263.981,327.449l-17.256-17.256l-17.256-17.256l25.453-34.451
			l43.51,43.51L263.981,327.449z"
        />
      </g>
    </g>
  </svg>
);

export default Palette;
