import React from 'react';

const Dough = () => (
  <svg height="512" role="img" viewBox="0 0 58 60" width="512" xmlns="http://www.w3.org/2000/svg">
    <g id="035---Mixing-Dough">
      <path className="color1" d="m46.86 12.56-8.44 8.44h-2.84l9.86-9.86c.3921222-.3921221 1.0278778-.3921221 1.42 0 .3921221.3921222.3921221 1.0278778 0 1.42z" id="Shape" />
      <path className="color1" d="m48.51 13.58c-.953045 2.5909731-2.169414 5.0773252-3.63 7.42h-2.36c1.5351047-2.3602914 2.8346763-4.8656511 3.88-7.48l-1.92-1.92c-5.3695589 2.2049672-10.2803333 5.3929008-14.48 9.4h-2.81c.1741428-.2392505.3681645-.463379.58-.67 4.7108145-4.7625969 10.3885739-8.4591081 16.65-10.84.352082-.11066088.7364932-.0184022 1 .24 1.82 1.86 1.18 1.18 2.82 2.83.2766439.2609918.3812896.6563199.27 1.02z" id="Shape" />
      <path className="color1" d="m54.59.59-9.85 9.84 2.82 2.83 9.85-9.85c.3755878-.37335029.5877672-.88042315.59-1.41-.0033854-.80631139-.4906258-1.5317303-1.2357575-1.83983461s-1.6024026-.13862704-2.1742425.42983461z" id="Shape" />
      <path className="color2" d="m18 26h14c1.6568542 0 3 1.3431458 3 3v12c0 .5522847.4477153 1 1 1s1-.4477153 1-1v-12c0-1.6568542 1.3431458-3 3-3s3 1.3431458 3 3v6c0 .5522847.4477153 1 1 1s1-.4477153 1-1v-6c0-1.6568542 1.3431458-3 3-3 .5522847 0 1-.4477153 1-1v-2h-32v2c0 .5522847.4477153 1 1 1z" id="Shape" />
      <path className="color3" d="m56 23h-5v2c0 1.6568542-1.3431458 3-3 3-.5522847 0-1 .4477153-1 1v6c0 1.6568542-1.3431458 3-3 3s-3-1.3431458-3-3v-6c0-.5522847-.4477153-1-1-1s-1 .4477153-1 1v12c0 1.6568542-1.3431458 3-3 3s-3-1.3431458-3-3v-12c0-.5522847-.4477153-1-1-1h-14c-1.6568542 0-3-1.3431458-3-3v-2h-13c-1.1045695 0-2 .8954305-2 2v1c.00000024 16.0162576 12.9837424 28.9999996 29 28.9999996s28.9999998-12.983742 29-28.9999996v-1c0-1.1045695-.8954305-2-2-2zm-52 10.28c-.59152653-2.0439753-.92742056-4.1533898-1-6.28-.00000001-.3572656.19059891-.6873926.49999999-.8660254s.69059894-.1786328 1.00000002 0 .5.5087598.49999999.8660254c.09881614 1.9403133.43464416 3.8612496 1 5.72.15463971.5522847-.16771527 1.1253602-.72000001 1.28-.55228474.1546397-1.12536024-.1677153-1.27999999-.72zm4.5 7.11c-.46147246.2993122-1.0780393.1697438-1.38-.29-.55198197-.8563324-1.05285574-1.7445486-1.5-2.66-.1895721-.3233242-.1824296-.7255093.01850332-1.0418984.20093292-.3163892.56191686-.4938561.93516279-.4597449.37324593.0341113.69608074.2740729.83633389.6216433.42769105.8410628.90520699 1.6558453 1.43 2.44.14602707.2236859.19648705.4964989.1401304.7576181-.05635665.2611191-.21484456.4888355-.4401304.6323819z" id="Shape" />
      <path className="color3" d="m38 55.66v2.34c0 1.1045695-.8954305 2-2 2h-14c-1.1045695 0-2-.8954305-2-2v-2.34c5.8672274 1.7850891 12.1327726 1.7850891 18 0z" id="Shape" />
    </g>
  </svg>
);

export default Dough;
