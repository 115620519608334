import React from 'react';

const Close = () => (
  <svg version="1.1" viewBox="0 0 32.526 32.526" x="0px" xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <polygon points="32.526,2.828 29.698,0 16.263,13.435 2.828,0 0,2.828 13.435,16.263 0,29.698 2.828,32.526 16.263,19.091
		29.698,32.526 32.526,29.698 19.091,16.263 "
    />
  </svg>
);

export default Close;
