import React from 'react';

const Gift = () => (
  <svg role="img" version="1.1" viewBox="0 0 512 512" x="0px"
	 xmlns="http://www.w3.org/2000/svg" y="0px"
  >
    <g transform="translate(1 1)">
      <g>
        <path d="M502.467,203.8h-8.533V41.667c0-13.653-11.093-25.6-25.6-25.6H298.52h-0.853h-84.48c-0.145,0-0.284,0.013-0.427,0.018
			c-0.143-0.005-0.281-0.018-0.427-0.018H41.667c-13.653,0-25.6,11.093-25.6,25.6V203.8H7.533c-5.12,0-8.533,3.413-8.533,8.533
			v85.333c0,5.12,3.413,8.533,8.533,8.533h8.533v162.133c0,13.653,11.093,25.6,25.6,25.6h170.667h85.333h170.667
			c13.653,0,25.6-11.947,25.6-25.6V306.2h8.533c5.12,0,8.533-3.413,8.533-8.533v-85.333C511,207.213,507.587,203.8,502.467,203.8z
			 M476.867,41.667V203.8h-77.227c-0.859-7.517-2.293-14.66-4.693-18.773c-8.533-15.36-23.893-24.747-42.667-24.747h-0.853
			c-16.876,0-31.91,11.341-44.373,24.469V33.133h161.28C472.6,33.133,476.867,36.547,476.867,41.667z M368.371,316.485
			c-0.242,0.274-0.48,0.557-0.731,0.808c-0.296,0.296-0.604,0.575-0.914,0.849c-0.214,0.186-0.432,0.366-0.653,0.542
			c-0.054,0.043-0.107,0.086-0.162,0.129c-6.412,4.963-15.69,5.358-24.273,3.648c-5.51-1.237-11.062-3.479-16.104-6.367
			c-12.684-7.793-24.213-21.044-25.256-37.289c1.432-2.703,2.635-5.544,3.568-8.503l22.833,17.125
			c1.707,0.853,3.413,1.707,5.12,1.707c2.56,0,5.12-0.853,6.827-3.413c2.56-3.413,1.707-9.387-1.707-11.947L311.889,255
			l25.031-18.773c3.413-2.56,4.267-8.533,1.707-11.947c-2.56-3.413-8.533-4.267-11.947-1.707l-22.833,17.125
			c-1.574-4.989-3.902-9.648-6.838-13.857c0.926-1.198,1.954-2.542,3.217-4.121c1.707-1.707,3.413-4.267,5.12-6.827
			c0.152-0.305,0.303-0.61,0.451-0.915c7.94-10.634,27.92-34.925,45.629-34.925c9.148,0,16.541,2.63,21.891,7.86
			c1.772,1.904,3.298,4.118,4.563,6.646c2.56,5.12,4.267,28.16,3.413,36.693c0,2.56,0,6.827,0,11.947
			c0,12.856-0.402,37.314-4.765,55.567C373.598,308.201,370.699,313.72,368.371,316.485z M293.278,359.96l-1.674,2.604
			c-0.39,0.293-0.74,0.612-1.049,0.951l-23.508-58.769c7.061-1.72,13.558-4.913,19.149-9.234c1.933,5.07,4.493,9.826,7.681,14.269
			c4.259,6.203,9.503,11.611,15.202,16.065l11.206,24.653l-14.085-2.486c-3.413,0-6.827,1.707-8.533,4.267L293.278,359.96z
			 M208.067,352.28c-1.707-2.56-5.12-4.267-7.68-4.267c-0.853,0-0.853,0-0.853,0l-14.507,2.56l9.386-20.479
			c0.009-0.006,0.019-0.012,0.028-0.018c14.281-8.409,24.413-20.956,29.602-34.376c5.427,4.144,11.696,7.233,18.497,8.95
			l-27.354,59.495L208.067,352.28z M141.507,317.293c-2.473-2.473-6.535-8.152-9.116-20.075c-0.052-0.417-0.13-0.837-0.271-1.258
			c-4.267-17.92-5.12-39.253-5.12-53.76c0,0,0,0,0,0c0-5.12,0-9.387,0-11.947c0-2.56,0-9.387,0.853-17.067
			c0-0.236-0.015-0.472-0.029-0.707c0.009-0.049,0.021-0.096,0.029-0.146c0.853-11.947,2.56-17.067,3.413-18.773
			c5.013-10.027,14.126-15.131,26.52-15.34c0.264-0.004,0.52-0.02,0.787-0.02c0.001,0,0.002,0,0.003,0
			c0.591,0,1.185,0.029,1.781,0.081c18.321,1.625,38.538,27.522,45.149,36.612c0.25,0.376,0.561,0.71,0.911,1.012
			c1.559,2.097,2.973,4.063,4.209,5.815c0.853,0.853,1.707,2.56,2.56,3.413c-3.094,4.42-5.494,9.301-7.096,14.518l-22.771-17.078
			c-3.413-2.56-9.387-1.707-11.947,1.707s-1.707,9.387,1.707,11.947L198.111,255l-25.031,18.773
			c-3.413,2.56-4.267,8.533-1.707,11.947c1.707,2.56,4.267,3.413,6.827,3.413c1.707,0,3.413-0.853,5.12-1.707l22.474-16.856
			c0.64,2.617,1.402,5.146,2.273,7.469c-0.853,19.627-16.213,34.987-32.427,41.813c-2.891,1.334-6.015,2.433-9.218,3.168
			C157.199,324.745,147.87,323.657,141.507,317.293z M284.997,238.762c0.002,0.004,0.004,0.007,0.006,0.011
			c0.153,0.28,0.293,0.568,0.438,0.852c2.147,4.36,3.414,9.04,3.651,13.754c0.025,0.537,0.041,1.077,0.041,1.62
			c0,0.73-0.027,1.46-0.078,2.188c-0.003,0.039-0.006,0.078-0.009,0.117c-0.255,3.464-1.102,6.884-2.511,10.106
			c-1.779,0.735-3.346,2.038-4.228,3.802c-5.973,10.24-17.067,17.067-29.013,17.067c-4.779,0-9.414-0.972-13.646-2.828
			c-5.225-2.654-9.691-6.607-12.953-11.436c-0.116-0.581-0.322-1.177-0.708-1.949c-2.974-4.461-4.65-9.57-5.033-14.762
			c-0.003-0.039-0.007-0.078-0.009-0.117c-0.051-0.728-0.078-1.458-0.078-2.188c0-0.54,0.016-1.076,0.041-1.61
			c0.406-7.984,3.856-15.779,11.052-20.577c0.981-1.307,1.582-2.866,1.812-4.482c5.839-4.663,13.22-7.465,21.228-7.465
			C267.906,220.867,279.198,228.126,284.997,238.762z M289.133,206.198c-1.183,1.534-2.325,3.021-3.413,4.429
			c-0.33,0.55-0.645,1.069-0.948,1.564c-0.353,0.218-0.627,0.394-0.722,0.723c-7.261-5.037-15.87-8.241-25.153-8.949
			c-0.056-0.005-0.111-0.008-0.167-0.013c-0.518-0.038-1.036-0.074-1.558-0.096c-0.724-0.034-1.448-0.055-2.172-0.055
			c-0.724,0-1.449,0.022-2.173,0.055c-0.52,0.022-1.035,0.058-1.551,0.096c-0.06,0.005-0.12,0.009-0.18,0.014
			c-7.534,0.576-14.625,2.793-20.912,6.305c-1.357,0.708-2.699,1.473-4.026,2.285c-0.057-0.076-0.114-0.146-0.171-0.222
			c-1.594-2.062-3.307-4.295-5.12-6.625V33.133h68.267V206.198z M33.133,41.667c0-4.267,4.267-8.533,8.533-8.533h162.987V185.88
			c-13.653-13.653-29.013-24.747-46.08-24.747c-19.627,0.853-34.133,9.387-42.667,24.747c-0.853,1.707-2.56,5.12-4.267,17.92H33.133
			V41.667z M16.067,220.867H24.6h85.333c0,4.267,0,8.533,0,11.093c0,1.707,0,5.973,0,10.24c0.853,11.947,0.853,29.867,3.413,46.933
			H24.6h-8.533V220.867z M33.133,468.333V306.2h83.916c2.807,9.856,6.813,18.195,12.511,23.893
			c7.68,6.827,17.92,11.093,29.867,11.093c3.58,0,7.164-0.387,10.831-1.128l-8.271,18.195c-1.707,2.56-0.853,5.973,0.853,8.533
			c2.56,2.56,5.12,4.267,8.533,3.413l24.747-4.267l7.68,13.653v97.28H41.667C37.4,476.867,33.133,473.453,33.133,468.333z
			 M221.72,389.827c0.853-0.853,1.707-1.707,2.56-3.413L255,319l26.453,67.413c0.018,0.053,0.041,0.107,0.06,0.16
			c0.905,2.506,3.424,4.96,6.767,4.96h0.478c0.042,0,0.375,0,0.375,0v85.333H221.72V389.827z M476.867,468.333
			c0,4.267-3.413,8.533-8.533,8.533H306.2V371.622l3.413-5.689l21.333,3.413c0.621,0,1.238-0.04,1.849-0.102l0.711,0.102
			c3.413,0,6.827-0.853,8.533-3.413s2.56-5.973,0.853-8.533l-8.939-18.69c5.572,1.638,11.298,2.477,16.619,2.477
			c11.947,0,22.187-4.267,31.573-12.8c5.365-5.365,9.135-13.076,11.818-22.187h82.902V468.333z M493.933,289.133H485.4h-87.775
			c2.281-15.046,2.888-31.9,3.295-47.787c0-4.267,0-7.68,0-10.24c0-1.938,0-5.665-0.137-10.24H485.4h8.533V289.133z"
        />
      </g>
    </g>
  </svg>
);

export default Gift;
