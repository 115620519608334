import cx from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import * as styles from './Menu.module.scss';

import { Close, IconMenu } from '../../icons';

export const Menu = ({ children }) => {
  const [isActive, setActive] = useState(false);
  return (
    <div className={styles.root}>
      <div className={cx(styles.menuContainer, { [styles.active]: isActive })}>
        <div className={styles.styledMenu}>{children}</div>
      </div>
      <div
        className={cx(styles.menuIcon, { [styles.active]: isActive })}
        onClick={() => {
          setActive(!isActive);
        }}
      >
        {isActive && <Close />}
        {!isActive && <IconMenu />}
      </div>
    </div>
  );
};
