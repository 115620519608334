import * as React from 'react';

import Banner from '../Banner';
import Container from '../Container';
import Decoration from '../Decoration';
import Footer from '../Footer';
import Heading from '../Heading';
import Nav from '../Nav';
import SEO from '../seo';

import * as styles from './Layout.module.scss';

export const Layout = ({ pagetitle, children, title }) => (
  <React.StrictMode>
    <SEO title={pagetitle || 'Stella Sweets'} />
    <Banner />
    <Nav />
    <main className={styles.main}>
      {title && (
        <Container>
          <Heading align="center">{title}</Heading>
        </Container>
      )}
      {children}
      <Container>
        <Decoration>
          <img alt="Stella Sweets Logo" src="/icon.png" />
        </Decoration>
      </Container>
    </main>

    <Footer />
  </React.StrictMode>
);
