import * as React from 'react';
import * as styles from './Nav.module.scss';

import { Link } from 'gatsby';
import Menu from '../Menu';
import { SearchIcon } from '../../icons';

export const Nav = () => (
  <nav className={styles.root}>
    <div className={styles.navContainer}>
      <Menu>
        <Link to="/">Home</Link>
        <Link to="/products/cakes">Products</Link>
        <Link to="/about">About</Link>
        <Link to="/faq">FAQ</Link>
        <Link to="/reviews">Reviews</Link>
        <Link to="/contact">Contact</Link>
        <Link to="/search">
          Search <SearchIcon />
        </Link>
      </Menu>
    </div>
  </nav>
);
