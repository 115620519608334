import React from 'react';

const Instagram = props => (
  <svg
    aria-labelledby="igTitle igDesc"
    role="img"
    version="1.1"
    viewBox="0 0 512 512"
    x="0px"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <title id="igTitle">Instagram</title>
    <desc id="igDesc">Instagram icon</desc>
    <path
      className={props.color1}
      d="M369.876,512H142.124C63.559,512,0,448.441,0,369.876V142.124C0,63.559,63.559,0,142.124,0h227.752
	C448.441,0,512,63.559,512,142.124v227.752C512,448.441,448.441,512,369.876,512"
    />
    <g>
      <path
        className={props.color2}
        d="M388.414,141.241c0-9.71-7.945-17.655-17.655-17.655c-9.71,0-17.655,7.945-17.655,17.655
		s7.945,17.655,17.655,17.655C380.469,158.897,388.414,150.952,388.414,141.241"
      />
      <path
        className={props.color2}
        d="M256,335.448c-42.372,0-79.448-37.076-79.448-79.448s37.076-79.448,79.448-79.448
		s79.448,37.076,79.448,79.448S298.372,335.448,256,335.448 M256,141.241c-63.559,0-114.759,51.2-114.759,114.759
		S192.441,370.759,256,370.759S370.759,319.559,370.759,256S319.559,141.241,256,141.241"
      />
      <path
        className={props.color2}
        d="M423.724,374.29c0,27.366-22.069,49.434-49.434,49.434H137.71
		c-27.366,0-49.434-22.069-49.434-49.434V137.71c0-27.366,22.069-49.434,49.434-49.434H374.29c27.366,0,49.434,22.069,49.434,49.434
		V374.29z M370.759,52.966H141.241c-48.552,0-88.276,39.724-88.276,88.276v229.517c0,48.552,39.724,88.276,88.276,88.276h229.517
		c48.552,0,88.276-39.724,88.276-88.276V141.241C459.034,92.69,419.31,52.966,370.759,52.966L370.759,52.966z"
      />
    </g>
  </svg>
);

export default Instagram;
