import cx from 'classnames';
import * as React from 'react';
import * as styles from './Heading.module.scss';

const getStyles = props => ({
  [styles.root]: true,
  [styles.h2]: true,
  [styles.bold]: props.weight === 'bold',
  [styles.center]: props.align === 'center',
  [styles.noMargin]: true,
  [styles[`h${props.level}`]]: props.level,
  [styles[`h${props.lookslike}`]]: props.lookslike,
});

export const Heading = props => {
  switch (props.level) {
    case 1:
      return <h1 className={cx(getStyles(props))}>{props.children}</h1>;
    case 3:
      return <h3 className={cx(getStyles(props))}>{props.children}</h3>;
    case 4:
      return <h4 className={cx(getStyles(props))}>{props.children}</h4>;
    default:
      return <h2 className={cx(getStyles(props))}>{props.children}</h2>;
  }
};
