import React from 'react';

const Yelp = props => (
  <svg
    aria-labelledby="yelpTitle yelpDesc"
    role="img"
    version="1.1"
    viewBox="0 0 511.852 511.852"
    x="0px"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <title id="yelpTitle">Yelp</title>
    <desc id="yelpDesc">Yelp icon</desc>
    <g>
      <g>
        <g>
          <path
            className={props.color1}
            d="M204.444,329.318c11.716-3.046,19.746-13.628,20.48-27c0.742-13.619-6.69-25.737-18.27-30.063l-23.142-9.429
				c-84.036-34.662-84.386-34.662-89.899-34.731c-9.079-0.469-17.493,4.284-22.869,12.723c-0.008,0.017-0.017,0.034-0.026,0.051
				c-10.325,16.469-12.39,60.911-9.865,85.402c1.178,10.709,3.098,18.458,5.828,23.612c4.582,8.764,12.783,14.174,21.768,14.473
				c0.589,0.034,1.118,0.068,1.673,0.068c4.864,0,12.117-2.295,81.655-24.653C195.287,332.27,202.199,330.06,204.444,329.318z"
          />
          <path
            className={props.color1}
            d="M244.952,346.151c-12.638-4.881-26.436-1.621-34.202,7.996c-0.009,0-14.524,17.271-16,18.97
				c-0.068,0.077-0.137,0.154-0.205,0.239c-58.803,69.009-58.957,69.461-60.8,74.778c-1.34,3.601-1.809,7.441-1.391,10.889
				c0.495,5.188,2.654,10.129,6.46,14.737c13.645,16.299,70.639,38.093,96.998,38.093c2.065,0,3.942-0.137,5.598-0.418
				c9.822-1.818,17.271-7.774,20.429-16.341c0.009-0.051,0.026-0.094,0.043-0.137c1.809-5.163,1.911-5.478,2.125-86.69
				c0,0,0.179-32.41,0.222-34.159C264.972,361.93,257.402,350.947,244.952,346.151z"
          />
          <path
            className={props.color1}
            d="M442.378,359.735c-4.403-3.294-4.574-3.413-81.792-28.757c0,0-29.517-9.754-32.333-10.761
				c-0.026-0.009-0.043-0.017-0.068-0.026c-11.358-4.412-24.124-0.546-32.495,9.779c-8.61,10.505-9.711,24.704-2.876,35.123
				l13.03,21.222c47.488,77.133,48.043,77.577,52.378,80.956c4.42,3.456,9.668,5.214,15.181,5.214c3.533,0,7.168-0.717,10.778-2.185
				c21.367-8.525,64.222-63.019,67.473-85.811C453.07,374.6,449.768,365.631,442.378,359.735z"
          />
          <path
            className={props.color1}
            d="M316.123,294.06c0.572,0.128,1.161,0.179,1.749,0.179c1.015,0,2.039-0.179,3.029-0.555
				c3.738-1.417,11.546-3.32,23.962-6.263c0.009,0,0.017,0,0.034-0.009c87.834-21.274,88.431-21.658,93.235-24.772
				c7.467-5.035,11.443-13.534,10.957-23.322c0.008-0.503-0.017-1.135-0.051-1.502c-2.483-23.629-40.542-79.778-60.851-89.711
				c-9.028-4.309-18.611-3.84-26.163,1.237c-4.642,3.012-4.642,3.012-52.779,68.847c0,0-19.379,26.359-20.301,27.588
				c-7.723,9.421-8.021,22.716-0.759,33.877C294.721,289.648,305.516,295.118,316.123,294.06z"
          />
          <path
            className={props.color1}
            d="M254.223,19.393c-2.526-9.207-9.702-15.932-19.712-18.458c-24.269-5.973-109.457,17.929-126.686,35.669
				c-6.835,7.142-9.549,16.495-7.27,24.994c0.137,0.512,0.324,0.998,0.546,1.476c2.398,4.984,80.461,127.863,104.269,165.291
				c9.984,16.213,20.941,24.354,32.572,24.354c2.765,0,5.555-0.461,8.38-1.374c14.942-4.506,21.948-18.603,20.813-41.899
				C265.862,180.101,255.836,28.404,254.223,19.393z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Yelp;
